import React from 'react';

import { createStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import getEnvironment from 'env-config/get-environment';
import queryStringParser from 'query-string';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import smartlookClient from 'smartlook-client';
import { DISCLOSURE_KEY, REFERRAL_ACCOUNT_IDENTIFIER_PARAM_KEY, RETURN_URL_KEY } from 'static/constants/UrlParamKeys';
import { runAPITest } from 'toolboxes/page-enter-functions/ALL';
import {
    setDisclousreType,
    setReferralAccountIdentifier,
    setReferralReturnUrl,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';
import './index.css';

import App from './components/global-components/App';
import { setIsDemoApp } from './redux/demo-app/root';
import { setFeatureFlags } from './redux/featureFlags/root';
import { rootReducer } from './redux/root';
import { setInitialParams } from './redux/urlParamTracking/root';
import { FeatureFlagResult } from './static/models/network/FeatureFlagResult';
import { getGlobalFeatureFlags } from './toolboxes/server-application-buffer/feature-flags-data';

const env = getEnvironment();
if (env.googleTagManagerId) {
    const tagManagerArgs = {
        gtmId: env.googleTagManagerId,
    };

    TagManager.initialize(tagManagerArgs);
}

if (env.smartlookKey) {
    smartlookClient.init(env.smartlookKey);
}

if (env.sentryUrl) {
    Sentry.init({ dsn: env.sentryUrl });
}

const passedInParams = queryStringParser.parse(window.location.search);
const isDemoApp =
    window.location.hostname.includes('demoefuneral') ||
    window.location.hostname.includes('demo.localhost') ||
    window.location.hostname.includes('demo.stagingefuneral');

if (passedInParams?.testingQuery) {
    runAPITest();
}

const store = createStore(rootReducer, devToolsEnhancer({}));
store.dispatch(setInitialParams(passedInParams));
store.dispatch(setIsDemoApp(isDemoApp));

for (const paramKey of Object.keys(passedInParams)) {
    try {
        if (paramKey.toLowerCase() === REFERRAL_ACCOUNT_IDENTIFIER_PARAM_KEY.toLowerCase()) {
            setReferralAccountIdentifier(passedInParams[paramKey] as string);
        }
        if (paramKey.toLowerCase() === DISCLOSURE_KEY.toLowerCase()) {
            setDisclousreType(passedInParams[paramKey] as string);
        }
        if (paramKey.toLowerCase() === RETURN_URL_KEY.toLowerCase()) {
            setReferralReturnUrl(passedInParams[paramKey] as string);
        }
    } catch (e) {}
}

function renderApp(featureFlags?: Array<FeatureFlagResult>) {
    if (featureFlags) {
        store.dispatch(setFeatureFlags(featureFlags));
    }

    if (passedInParams?.testingQuery) {
        return null;
    }

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
}

getGlobalFeatureFlags().then(renderApp).catch(renderApp);
