import React, { useEffect, useState } from 'react';

import { Dialog, DialogActions, DialogContent, Grid, makeStyles } from '@material-ui/core';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import {
    checkIfExistsInSessionStorage,
    clearDisclosureType,
    clearReferralReturnUrl,
    DISCLOSURE_TYPE_KEY,
    getDisclosureType,
    getReferralReturnUrl,
    REFERRAL_RETURN_URL_KEY,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import ExternalLinkComponent from '../external-link-component/ExternalLinkComponent';
import ButtonComponent from '../layout-components/button-components/button-component/ButtonComponent';

const useStyles = makeStyles({
    footerText: {
        textDecoration: 'none',
    },
    transferModalButtons: {
        padding: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
});

const TransferDisclosureModalComponent: React.FunctionComponent<PropShape> = () => {
    const classes = useStyles();
    const { selectedAccount } = useSelectedAccount();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (checkIfExistsInSessionStorage(DISCLOSURE_TYPE_KEY) && getDisclosureType().toLowerCase() === 'transfer') {
            if (!showModal) {
                setShowModal(true);
            }
        }
    }, [showModal]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showModal) {
            timer = setTimeout(() => {
                onAcceptClick();
            }, 10000); // Auto-close in 10 seconds
        }
        return () => clearTimeout(timer); // Cleanup timer on unmount or modal close
    }, [showModal]);

    const onAcceptClick = () => {
        clearDisclosureType();
        clearReferralReturnUrl();
        setShowModal(false);
    };

    const onDeclineClick = () => {
        const redirectUrl = checkIfExistsInSessionStorage(REFERRAL_RETURN_URL_KEY)
            ? getReferralReturnUrl()
            : selectedAccount.website;
        clearDisclosureType();
        if (redirectUrl) {
            clearReferralReturnUrl();
            window.location.href = redirectUrl;
        } else {
            console.log('transfer modal: no url to return user to');
        }
        setShowModal(false);
    };

    return (
        <Dialog open={showModal} onClose={onAcceptClick}>
            <DialogContent>
                You have left {selectedAccount?.name} and are visiting our trusted partner, eFuneral. You will be
                subject to eFuneral's {''}
                <span>
                    <ExternalLinkComponent
                        id="privacy-policy"
                        href="https://www.efuneral.com/privacy"
                        className={classes.footerText}
                    >
                        privacy policy
                    </ExternalLinkComponent>
                </span>{' '}
                and{' '}
                <span>
                    <ExternalLinkComponent
                        id="terms-of-use"
                        href="https://www.efuneral.com/terms-of-use"
                        className={classes.footerText}
                    >
                        terms of use
                    </ExternalLinkComponent>
                </span>
                .
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12} className={classes.transferModalButtons}>
                        <ButtonComponent fullWidth size="small" id="accept-transfer-disclosure" onClick={onAcceptClick}>
                            Accept and Continue
                        </ButtonComponent>
                    </Grid>
                    <Grid item xs={12} className={classes.transferModalButtons}>
                        <ButtonComponent
                            fullWidth
                            size="small"
                            color="secondary"
                            id="decline-transfer-disclosure"
                            onClick={onDeclineClick}
                        >
                            Go Back
                        </ButtonComponent>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

interface PropShape {
    // Shape of passed in props
}

TransferDisclosureModalComponent.defaultProps = {};

export default TransferDisclosureModalComponent;
