import { RootState } from 'redux/root';
import { v4 } from 'uuid';

const OPPORTUNITY_LOCAL_STORAGE_KEY = 'opportunityKey';
export const ORIGIN_TOKEN_LOCAL_STORAGE_KEY = 'originToken';
export const REFERRAL_ACCOUNT_IDENTIFIER = 'referralAccountIdentifier';
export const REFERRAL_RETURN_URL_KEY = 'referralReturnUrl';
export const DISCLOSURE_TYPE_KEY = 'disclosureType';

export function getOpportunityIdLocalStorage(state: RootState): string {
    const today = new Date().toISOString();
    const storedOpportunity = window.localStorage.getItem(OPPORTUNITY_LOCAL_STORAGE_KEY);

    if (!storedOpportunity) {
        return state.opportunity.opportunityId ? state.opportunity.opportunityId : null;
    } else {
        const opportunity = JSON.parse(storedOpportunity);

        if (today > opportunity.expiration) {
            clearOpportunityIdLocalStorage();
            return null;
        } else {
            return state.opportunity.opportunityId || opportunity.opportunityId;
        }
    }
}

export function setOpportunityIdLocalStroage(oppId: string, closeDate?: string): void {
    let closeDateTime = new Date();

    if (closeDate) {
        // Close Date format is ISO format YYYY-MM-DD
        closeDateTime = new Date(closeDate);
    } else {
        closeDateTime.setUTCDate(closeDateTime.getUTCDate() + 30);
    }

    const opportunityKey = {
        opportunityId: oppId,
        expiration: closeDateTime,
    };
    window.localStorage.setItem(OPPORTUNITY_LOCAL_STORAGE_KEY, JSON.stringify(opportunityKey));
}

export function getMembershipQuestionSkipSessionStorage(): boolean {
    return window.sessionStorage.getItem('MembershipQuestionSkip') === 'true' ? true : false;
}

export function setMembershipQuestionSkipSessionStorage(): void {
    window.sessionStorage.setItem('MembershipQuestionSkip', 'true');
}

export function checkIfExistsInLocalStorage(key: string): boolean {
    const storedKey = window.localStorage.getItem(key);
    return storedKey ? true : false;
}

export function clearOpportunityIdLocalStorage(): void {
    window.localStorage.removeItem(OPPORTUNITY_LOCAL_STORAGE_KEY);
}

// Ticket EFIT-761 - Keep a unique key for users that can be used across opportunities for marketing data purposes
export function getOrGenerateOriginToken(): string {
    try {
        const originToken = window.localStorage.getItem(ORIGIN_TOKEN_LOCAL_STORAGE_KEY);
        if (originToken) {
            return originToken;
        } else {
            const newOriginToken = v4();
            window.localStorage.setItem(ORIGIN_TOKEN_LOCAL_STORAGE_KEY, newOriginToken);
            return newOriginToken;
        }
    } catch (e) {
        // Don't stop the rest of the process from happening
        console.error(e);
        return '';
    }
}

export function setReferralAccountIdentifier(referralAccountIdentifier: string): void {
    window.localStorage.setItem(REFERRAL_ACCOUNT_IDENTIFIER, referralAccountIdentifier);
}

export function getReferralAccountIdentifier(): string | null {
    return window.localStorage.getItem(REFERRAL_ACCOUNT_IDENTIFIER);
}

export function setDisclousreType(disclosureType: string): void {
    window.sessionStorage.setItem(DISCLOSURE_TYPE_KEY, disclosureType);
}

export function getDisclosureType(): string | null {
    return window.sessionStorage.getItem(DISCLOSURE_TYPE_KEY);
}

export function clearDisclosureType(): void {
    window.sessionStorage.removeItem(DISCLOSURE_TYPE_KEY);
}

export function setReferralReturnUrl(returnUrl: string): void {
    window.sessionStorage.setItem(REFERRAL_RETURN_URL_KEY, returnUrl);
}

export function getReferralReturnUrl(): string | null {
    return window.sessionStorage.getItem(REFERRAL_RETURN_URL_KEY);
}

export function clearReferralReturnUrl(): void {
    window.sessionStorage.removeItem(REFERRAL_RETURN_URL_KEY);
}

export function checkIfExistsInSessionStorage(key: string): boolean {
    const storedKey = window.sessionStorage.getItem(key);
    return storedKey ? true : false;
}
