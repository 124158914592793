import React, { Fragment, useEffect, useState } from 'react';

import { CssBaseline, Grid, makeStyles } from '@material-ui/core';
import AccountThemeProvider from 'components/global-components/account-theme-provider-component/AccountThemeProvider';
import RoutingWrapper from 'components/routing-component/RoutingWrapper';
import { useIsDemoApp } from 'hooks/useIsDemoApp';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import TagManager from 'react-gtm-module';
import Helmet from 'react-helmet';
import IdleTimer from 'react-idle-timer';
import { BrowserRouter } from 'react-router-dom';
import { GeographicState } from 'static/models/enums/states';
import { shouldShowDemoCustomComponents } from 'toolboxes/helpers/DemoAppHelpers';
import {
    checkIfExistsInSessionStorage,
    DISCLOSURE_TYPE_KEY,
    getDisclosureType,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import ContinueBarComponent from './continue-bar-component/ContinueBarComponent';
import DemoAppIdleComponent from './demo-app/DemoAppIdleComponent';
import FooterComponent from './footer-component/FooterComponent';
import HeaderComponent from './limb-components/header-component/HeaderComponent';
import TransferDisclosureModalComponent from './transfer-disclosure-modal/TransferDisclosureModal';

const useStyles = makeStyles({
    pageContainer: {
        minHeight: '100vh',
    },
    contentContainer: {
        display: 'flex',
        flexGrow: 1,
    },
});

const IdleTimeOut = 60 * 4 * 1000; // 4 minutes

const App: React.FunctionComponent<PropShape> = () => {
    const classes = useStyles();
    const { selectedAccount } = useSelectedAccount();
    const [timeOutFlag, setTimeOut] = useState(false);
    const { isDemoApp } = useIsDemoApp();

    useEffect(() => {
        if (selectedAccount.googleTagManagerId) {
            const tagManagerArgs = {
                gtmId: selectedAccount.googleTagManagerId,
                dataLayerName: 'accountDataLayer',
            };
            TagManager.initialize(tagManagerArgs);
        }
    }, [selectedAccount.googleTagManagerId]);

    const timeOut = () => {
        const show = shouldShowDemoCustomComponents(isDemoApp);
        if (show) {
            setTimeOut(true);
        }
    };

    const resetTimer = () => setTimeOut(false);

    return (
        <AccountThemeProvider>
            <Helmet>
                <meta
                    name="description"
                    content={`${selectedAccount.name} gives you the ability to plan, select, and purchase the personalized funeral arrangement you prefer, all online. At your fingertips. On your terms.`}
                    data-react-helmet="true"
                />
            </Helmet>
            <IdleTimer timeout={IdleTimeOut} onIdle={timeOut} />
            <DemoAppIdleComponent show={timeOutFlag} callback={resetTimer} />

            {checkIfExistsInSessionStorage(DISCLOSURE_TYPE_KEY) && getDisclosureType().toLowerCase() === 'transfer' && (
                <TransferDisclosureModalComponent />
            )}

            <BrowserRouter>
                <CssBaseline />
                <Grid container direction="column" justifyContent="space-between" className={classes.pageContainer}>
                    {selectedAccount.id && <HeaderComponent />}
                    <Grid item className={classes.contentContainer}>
                        <RoutingWrapper showDebugInfo={true} />
                    </Grid>
                    {selectedAccount.id && (
                        // TODO determine the real logic for showing funeral home number. Luke said California requires it, but
                        // guided shows it for _any_ account that has a funeral home number populated
                        <Fragment>
                            <ContinueBarComponent />
                            <FooterComponent showFuneralHomeNumber={selectedAccount.state === GeographicState.CA} />
                        </Fragment>
                    )}
                </Grid>
            </BrowserRouter>
        </AccountThemeProvider>
    );
};
interface PropShape {
    // Shape of passed in props
    //applicationMuiTheme: any;
    //applicationTheme: CSSProperties;
}
App.defaultProps = {
    // Set default values for props
};
export default App;
